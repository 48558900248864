import type { GetShopItems_getShopItems } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import type { ActiveDealFilters } from '../provider/types'
import { useDealFilters } from '../DealFiltersProvider'
import { PurchaseCategory, PurchaseType } from '@g4g/graphql/src/shop/__generated__/globalTypes'
import { useActiveRecipientShopItems } from '../../shared/hooks/useActiveRecipientShopItems'
import { calculateRewardTotals, uniqueShopItems } from '@/shop/src/utils/shopItemUtils'
import type { CartProduct } from '../../shopping-cart/provider/types'

const priceFloat = ({
  purchaseCategory,
  lootRewardPackage: { bundlePrice, productPrice },
}: GetShopItems_getShopItems) =>
  parseFloat(
    purchaseCategory === PurchaseCategory.Bundle ? bundlePrice?.price ?? '0' : productPrice.price
  )

const sortByPriceLoToHi = (a: GetShopItems_getShopItems, b: GetShopItems_getShopItems) =>
  priceFloat(a) - priceFloat(b)

const sortByPriceHiToLo = (a: GetShopItems_getShopItems, b: GetShopItems_getShopItems) =>
  priceFloat(b) - priceFloat(a)

const sortByPrice = (items: GetShopItems_getShopItems[], ascending: boolean) =>
  [...items].sort((a, b) => (ascending ? sortByPriceLoToHi(a, b) : sortByPriceHiToLo(a, b)))

// This is to add the total loot from PurchaseCategory Bundles
// together into one shopItem loot reward package
export const withTotalRewards = (
  items: GetShopItems_getShopItems[]
): GetShopItems_getShopItems[] => {
  const cache = new Map()
  return items.map((shopItem) => {
    // Only need to do this for bundles atm
    if (shopItem.purchaseCategory === PurchaseCategory.Bundle) {
      if (!cache.has(shopItem.shopItemId)) {
        const bundleProducts = items
          .filter((i) => i.shopItemId === shopItem.shopItemId)
          .map((i) => ({ product: i, totalQuantity: 1 } as CartProduct))
        const totalBundleLoot = calculateRewardTotals(bundleProducts)
        cache.set(shopItem.shopItemId, totalBundleLoot.loot)
      }

      return {
        ...shopItem,
        lootRewardPackage: {
          ...shopItem.lootRewardPackage,
          lootRewards: cache.get(shopItem.shopItemId),
        },
      }
    }

    return shopItem
  })
}

// In tick filters make purchase category & type the same for simplicity
const hasCategory = (category: PurchaseCategory | PurchaseType, categories: Set<string>) =>
  categories.has(category.toString().toLowerCase())

export const applyFilters = (
  items: GetShopItems_getShopItems[],
  { categories, resources }: Readonly<ActiveDealFilters>
) => {
  const _resources = Array.from(resources)
  return uniqueShopItems(withTotalRewards(items)).filter(
    (item) =>
      (categories.size === 0 ||
        // For categories we can filter the purchase type
        // (subscription) or the purchase category (bundle, special, etc.)
        // so we mix them together to make it easier for the user
        hasCategory(item.purchaseCategory, categories) ||
        hasCategory(item.purchaseType, categories)) && // We create an intersection
      // between the category and the resource filters
      (resources.size === 0 ||
        // Set to an Array to check if
        // [Filter Array] has all Elements of the [Loot Array]
        // so we get an intersection inside the resource
        // filters
        _resources.every((r) =>
          item.lootRewardPackage.lootRewards?.find(
            (l) => l.rewardType.toString().toLowerCase() === r
          )
        ))
  )
}

export const applySort = (
  items: GetShopItems_getShopItems[],
  { sortBy }: Readonly<ActiveDealFilters>
) => {
  switch (sortBy) {
    case 'featured':
      return items
    case 'price-low-high':
      return sortByPrice(items, true)
    case 'price-high-low':
      return sortByPrice(items, false)
    default:
      return items
  }
}

export const applyFiltersAndSort = (
  items: GetShopItems_getShopItems[],
  activeFilters: Readonly<ActiveDealFilters>
) => applySort(applyFilters(items, activeFilters), activeFilters)

// these deals are bugged so we just hack the front end to not show them cuz its easiest
const hackedHiddenDeals = ['monthlypetskin', 'monthlyheroskin']

export function useFilteredDeals() {
  const res = useActiveRecipientShopItems()
  const activeFilters = useDealFilters()

  if (!res.data || !res.data.getShopItems) {
    return {
      ...res,
      data: [],
    }
  }

  return {
    ...res,
    data: applyFiltersAndSort(
      res.data.getShopItems.filter((s) => !hackedHiddenDeals.some((l) => l === s.shopItemId)),
      activeFilters
    ),
  }
}
